import React, {useState} from 'react';
import "./Features.css";

import Download from '../Download/Download';

import promoMedia from '../../../Assets/Digital-Interactions-Page-Media.png'

const Features = () => {

    const [firstKeyword, setFirstKeyword] = useState(["Organize", "Generate", "Share", "Stream", "Engage", "Capture", "Take"]);
    const [secondKeyword, setSecondKeyword] = useState(["Documents", "Reports", "Work", "Videos", "Customers", "Images", "Notes"]);
    const [curIndex, setCurIndex] = useState(0);

    const getWidth = () => {
        return window.innerWidth*0.6;
    }

    const getHeight = () => {
        return window.innerWidth*0.3375
    }

  return(
    <div className="features-outter">
        <div className="feature-listing">
            <div className="feature-text">
                <p className='brochure-title'>
                Create Digital Interactions
                </p>
                <p className='brochure-text'>
                    Combine your videos, pictures and plans all on one platform.
                </p>
                <p className='brochure-text'>
                    Build smart floor plans and market them with a global audience;
                </p>
                <p className='brochure-text'>
                    igniting lead generation.
                </p>
            </div>
            <div className="feature-media">
                <video height={getHeight()} width={{getWidth}} className="promoVideo" controls>
                    <source src="https://d13dvi4jdbb8bg.cloudfront.net/mapit-promo-video-2.mp4" type="video/mp4"></source>
                </video>
            {/* <iframe 
                className='promoVideo'
                style={{width: getWidth(), height: getHeight()}}
                src="https://d13dvi4jdbb8bg.cloudfront.net/mapit-promo-video-2.mp4"
                allowFullScreen
              />     */}
    
                {/* <img src={promoMedia} style={{borderRadius: 20}}width={getWidth()} height={getHeight()}/> */}
              
            </div>
        </div>
        {/* <div className="feature-cloud">
            <div className="feature-text">
                <p className='cloud-title'>
                Unlimited Cloud Storage for your documents, videos and photos.
                </p>
            </div>
            <div className="feature-media">
                <iframe 
                className='scheduling-ad'
                src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                frameBorder="0"
                allow="accelerometer"
                allowFullScreen
                />            
            </div>
        </div>
        <div className="feature-listing">
            <div className="feature-media">
            <iframe 
                className='scheduling-ad'
                src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                frameBorder="0"
                allow="accelerometer"
                allowFullScreen
                />              </div>
            <div className="feature-text">
                <p className='feature-p'>
                Market your listings by creating digital brocures and sharing them with a global audience
                Generate QR codes to share the listing with potential buyers
                </p>
            </div>
        </div>
        <div className="feature-listing">
            <div className="feature-text">
                <p className='feature-p'>
                Unlimited Cloud Storage for your documents, videos and photos.
                </p>
            </div>
            <div className="feature-media2">
            <iframe 
                className='scheduling-ad'
                src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                frameBorder="0"
                allow="accelerometer"
                allowFullScreen
                />              </div>
        </div> */}
    </div>
  );
}

export default Features;