import React from 'react';
import "./DownloadApp.css";

import googleIcon from '../../../Assets/GooglePlayIcon.png';
import appleIcon from '../../../Assets/AppleAppStore.png'

const DownloadApp = () => {

  return(
    <div className="mapit-download">
        <div className="download-text">
            <div className="download-div-title">
                <p className="download-1">
                Grow, Market and Organize
                </p>
                <p className="download-3">
                Available on your featured App Store
                </p>
            </div>
            <div className="download-div-text">
                <p className='download-2'>
                Everything you need to organize your clients and team.
                </p>
            </div>
        </div>
        <div className="download-tiles">
            <div className="download-tile1">
                <div className="download-tile-image">
                <iframe 
                    className='download-feature1'
                    src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                    frameBorder="0"
                    allow="accelerometer"
                    allowFullScreen
                />
                </div>
                <div className="download-tile-text">
                    <div className="download-tile-title">
                        <p className="download-tile-1">
                        Remote Work Validation
                        </p>
                    </div>
                    <div className="download-tile-desc">
                        <p className='download-tile-2'>
                        Monitor your sites with video and image updates.<br></br>
                        Track daily reports and mitigate on-site risk.
                        </p>
                    </div>
                </div>
            </div>
            <div className="download-tile2">
                <div className="download-tile-image">
                    <iframe 
                        className='download-feature1'
                        src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                        frameBorder="0"
                        allow="accelerometer"
                        allowFullScreen
                    />
                </div>
                <div className="download-tile-text">
                    <div className="download-tile-title">
                        <p className="download-tile-1">
                        Market Your Projects
                        </p>
                    </div>
                    <div className="download-tile-desc">
                        <p className='download-tile-2'>
                       Share your projects on social media using weblinks.<br></br>Or generate a QR code for on-site marketing.
                        </p>
                    </div>
                </div>
            </div>
            <div className="download-tile3">
                <div className="download-tile-image">
                    <iframe 
                        className='download-feature1'
                        src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                        frameBorder="0"
                        allow="accelerometer"
                        allowFullScreen
                    />
                </div>
                <div className="download-tile-text">
                    <div className="download-tile-title">
                        <p className="download-tile-1">
                        Improve Customer Service
                        </p>
                    </div>
                    <div className="download-tile-desc">
                        <p className='download-tile-2'>
                        Notify clients with updates and reminders on projects.<br></br>Close deals faster by communicating uptodate information.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default DownloadApp;