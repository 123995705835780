import React, {useState, useEffect} from 'react';
import "./MIndustries.css";

import MStartNow from '../MStartNow/MStartNow';

const MIndustries = () => {

  const industryList = ["RE", "C", "ID","T","BM"];

  const fullIndstNames = ["Real Estate", "Construction", "Interior Design", "Trades", "Building Mangement"]

  const [curIndst, setCurIndst] = useState(0);
  const [clickIdx, setClickIdx] = useState(0);

  useEffect(() => {
    setTimeout(incrementCurIndex, 6000);
  }, [curIndst]);

  const incrementCurIndex = () => {
    setClickIdx((clickIdx+1)%industryList.length);
    setCurIndst((clickIdx+1)%industryList.length);
  }

  const GetIndustryInfo = () => {

      switch(industryList[clickIdx]) {
        case "RE":
          return (
          <div className="mindustry-desc">
            <div className="mindustries-div2">
              <p className='mindustries-4'>
              Advertise your listings with QR codes and weblinks. Schdule buyer viewings. 
              Generate buyer reports on the number of houses seen, average sale prices, etc. 
              Close deals faster with notificatons and reminders.
              </p>
            </div> 
            <div className="mindustry-fpImgDiv">
              {/* <iframe 
                className='industry-FloorplanImg'
                src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapItLogo-384x146.png"
                frameBorder="0"
                allow="accelerometer"
                allowFullScreen
              /> */}
              <img src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapItLogo-384x146.png" width="157.81vh" height="60vh"/>
            </div>        
          </div>
          );
        case "C":
          return (
          <div className="mindustry-desc">
            <div className="mindustries-div2">
              <p className='mindustries-4'>
              Efficiently oversee your team, coordinate projects, maintain daily progress reports, and ensure thorough documentation to minimize legal risks.
              </p>
            </div> 
            <div className="mindustry-fpImgDiv">
              {/* <iframe 
                className='industry-FloorplanImg'
                src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapItLogo-384x146.png"
                frameBorder="0"
                allow="accelerometer"
                allowFullScreen
              /> */}
              <img src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapItLogo-384x146.png" width="157.81vh" height="60vh"/>
            </div>       
          </div>
          );
        case "ID":
          return (
          <div className="mindustry-desc">
            <div className="mindustries-div2">
              <p className='mindustries-4'>
              Conduct site inspections with ease, generate on-site report and perform validations directly on your plans. Streamline communication and access reports on the go.              </p>
            </div> 
            <div className="mindustry-fpImgDiv">
              {/* <iframe 
                className='industry-FloorplanImg'
                src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapItLogo-384x146.png"
                frameBorder="0"
                allow="accelerometer"
                allowFullScreen
              /> */}
              <img src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapItLogo-384x146.png" width="157.81vh" height="60vh"/>
            </div>         
          </div>
          );
        case "T":
          return (
           <div className="mindustry-desc">
            <div className="mindustries-div2">
              <p className='mindustries-4'>
              Facilitate on-site communication between trades, report and address issues effectively, organize your tasks, and document your work to mitigate liabilities.              
              </p>
            </div> 
            <div className="mindustry-fpImgDiv">
              {/* <iframe 
                className='industry-FloorplanImg'
                src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapItLogo-384x146.png"
                frameBorder="0"
                allow="accelerometer"
                allowFullScreen
              /> */}
              <img src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapItLogo-384x146.png" width="157.81vh" height="60vh"/>
            </div>      
          </div>
          );
        case "BM":
          return (
          <div className="mindustry-desc">
            <div className="mindustries-div2">
              <p className='mindustries-4'>
              Efficiently communicate building issues with precise location details, stay updated on each building's progress, and effectively relay concerns to contractors.
              </p>
            </div> 
            <div className="mindustry-fpImgDiv">
              {/* <iframe 
                className='industry-FloorplanImg'
                src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapItLogo-384x146.png"
                frameBorder="0"
                allow="accelerometer"
                allowFullScreen
              /> */}
              <img src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapItLogo-384x146.png" width="157.81vh" height="60vh"/>
            </div>        
          </div>
          );
      }
  }

  return(
    <div className="mindustries-outter">
      <div className="mindustries-inner">
        <div className="mindustries-div">
            <p className="mindustries-1">
              Create Smart Building Plans
            </p>
            <p className='mindustries-2'>
              Easy on-site communicatiion with your clients and team
            </p>
        </div>
        <div className="mindustry-list-outter">
          <div className="mindustry-list">
            <p onClick={() => setClickIdx(0)} className='mindustries-3' style={industryList[clickIdx]==="RE"?{color: "#FFFFFF"}:{}}>
              {fullIndstNames[clickIdx]}
            </p>
            {/* <p onClick={() => setClickIdx(1)} className='industries-3' style={industryList[clickIdx]==="C"?{color: "#FFFFFF"}:{}}>
              Construction
            </p>
            <p onClick={() => setClickIdx(2)} className='industries-3' style={industryList[clickIdx]==="ID"?{color: "#FFFFFF"}:{}}>
              Interior Design
            </p>
            <p onClick={() => setClickIdx(3)} className='industries-3' style={industryList[clickIdx]==="T"?{color: "#FFFFFF"}:{}}>
              Trades
            </p>
            <p onClick={() => setClickIdx(4)} className='industries-3' style={industryList[clickIdx]==="BM"?{color: "#FFFFFF"}:{}}>
              Building Management
            </p> */}
          </div>
          <GetIndustryInfo/>
        </div>
      </div>
      <MStartNow divStyle="mStartNow-outter-indst" buttonStyle="mgetStartedButtonIndst"></MStartNow>
    </div>
  );
}

export default MIndustries;