import React, {useState} from 'react';
import "./FeatureGrid.css";

import Download from '../Download/Download';

import qrcode from '../../../Assets/QR_Code.png'
import listing from '../../../Assets/Listing-example.png'
import cloud from '../../../Assets/cloud_storage.png'
import smartPlan from '../../../Assets/Digital-Interactions-Page-Media.png'



const FeatureGrid = () => {

    const getWidth = (type) => {
        const win_width = window.innerWidth;
        let width = 0;
        switch (type) {
            case "qr":
                width = win_width*0.182;
                break;
            case "smart":
                width = win_width*0.36;
                break;
            case "listing":
                width=win_width*0.26;
                break;
            case "cloud":
                width=win_width*0.26;
                break;
            default:
                width=win_width*0.26;
        }
        return width;
    }

    const getHeight = (type) => {
        const win_height = window.innerHeight;
        const win_width = window.innerWidth;
        let height = 0;
        switch (type) {
            case "qr":
                height = win_width*0.182;
                break;
            case "smart":
                height = win_height*0.42;
                break;
            case "listing":
                height=win_width*0.26;
                break;
            case "cloud":
                height=win_width*0.26;
                break;
            default:
                height=win_width*0.26;
        }
        return height;
    }

  return(
    <div className="feature-grid-outter">
        <div className="feature-grid-text">
            <p className='team-collab-title'>
            Team Collaboration
            </p>
            <p className='team-collab-text'>
            In Every Way Possible.
            </p>
        </div>
        <div className="feature-grid-inner">
            <div className="grid-item">
                <div className="schedule-feature">
                    {/* <iframe 
                        className='feature1'
                        src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                        frameBorder="0"
                        allow="accelerometer"
                        allowFullScreen
                    /> */}
                    <img 
                    src={qrcode} 
                    width={getWidth("qr")} 
                    height={getHeight("qr")}
                    alt="qr code virtual tour"/>
                </div>
                <div className="feature-desc">
                    <p className="client-schedule-title">Generate QR Codes</p>
                    <p className="client-schedule-p">With custom-generated QR codes for your open houses, engaging buyers has never been easier. Transform mundane tours into memorable experiences, allowing buyers to relive your listing long after they've left. </p>
                </div>
            </div>
            <div className="grid-item2">
                <div className="schedule-feature">
                {/* <iframe 
                    className='feature1'
                    src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                    frameBorder="0"
                    allow="accelerometer"
                    allowFullScreen
                /> */}
                <img src={smartPlan} 
                width={getWidth("smart")} 
                height={getHeight("smart")}
                alt="interactive floor plan"/>
                </div>
                <div className="feature-desc">
                    <p className="client-schedule-title">Blend Your Photos and Plans</p>
                    <p className="client-schedule-p">Generate intelligent floor plans enhanced with images and videos to present to prospective buyers.</p>
                </div>
            </div>
        </div>
        <div className="feature-grid-inner">
            <div className="grid-item">
                <div className="schedule-feature">
                    {/* <iframe 
                        className='feature1'
                        src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                        frameBorder="0"
                        allow="accelerometer"
                        allowFullScreen
                    /> */}
                    <img 
                    src={listing} 
                    width={getWidth("listing")}
                    height={getHeight("listing")}
                    alt="real estate listing"/>
                </div>
                <div className="feature-desc">
                    <p className="client-schedule-title">Help Buyers Recall Your Listing</p>
                    <p className="client-schedule-p">Say goodbye to forgettable listings and hello to unforgettable impressions. 
                    Make your property the talk of the town with our innovative solution. Stand out. Be remembered. Experience the future of home tours today.</p>
                    {/* <p className="client-schedule-p">Offer a comprehensive package of documents, videos, images, and financial data to provide your investment clients with all the information they need.</p> */}
                </div>
            </div>
            <div className="grid-item2">
                <div className="schedule-feature">
                {/* <iframe 
                    className='feature1'
                    src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                    frameBorder="0"
                    allow="accelerometer"
                    allowFullScreen
                /> */}
                    <img 
                    src={cloud} 
                    width={getWidth("cloud")} 
                    height={getHeight("cloud")}
                    alt="storage for real estate plans, photos and videos"/>
                </div>
                <div className="feature-desc">
                    <p className="client-schedule-title">Unlimited Cloud Storage</p>
                    <p className="client-schedule-p">Efficiently organize and securely store your smart floor plans, making them easily accessible from any device.</p>
                </div>
            </div>
        </div>
    </div>
  );
}

export default FeatureGrid;