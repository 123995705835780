import React from 'react';
import "./MStartNow.css";

import MGetStarted from '../MGetStarted/MGetStarted'

const MStartNow = ({divStyle, buttonStyle}) => {

  return(
    <div className={divStyle}>
      <div className="mStartNow-inner">
        <div className="mStartNow-div-title">
            <p className="mStartNow-1">
              Business Communication
            </p>
            <p className="mStartNow-3">
              with smart building plans.<br/>
            </p>
        </div>
        <div className="mStartNow-div-text">
            <p className='mStartNow-2'>
              Validate drawings and on-site construction with ease.
            </p>
        </div>
        <MGetStarted buttonStyle={buttonStyle}></MGetStarted>
      </div>
    </div>
  );
}

export default MStartNow;