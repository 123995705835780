import React, {useState} from 'react';
import "./ContactUs.css";

import {TailSpin} from 'react-loader-spinner';
import MapItButton from '../../Common/MapItButton/MapItButton';
import {isMobile} from 'react-device-detect';

import * as Security from '../../Security/Security';

import callCenter from '../../../Assets/call_center_2.png';

import PageTitle from '../../Common/PageTitle/PageTitle'; // Adjust the path as per your file structure

const prod_host = "https://infinite-mountain-18925.herokuapp.com";

const ContactUs = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [displayMsg, setDisplayMsg] = useState(false);

    const onSubmit = () => {
        setClicked(!clicked);
        if(Security.valEmailInput(email)&&Security.valNameInput(name)&&Security.valNotesInput(message)) {
          console.log("send network request to submit password data and access code")
          setLoading(true);
          fetch(`${prod_host}/contactus`, {
          method: 'post',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            name: name,
            email: email,
            message: message
          })
          })
          .then(response => response.json())
          .then(msg => {
            setLoading(false);
            setDisplayMsg(true);
            if(msg === "password updated") {
                // let user know we have recieved your message
            } else {
              console.log(msg);
              // let user know auth failed - todo
            }
          })
          .catch(console.log);
        } else {
          console.log("Debug Log: user input did not pass security validation test");
          // let user know something is wrong wiht input - todo
        }
    }

    const onNameChange = (name) => {
        setName(name.target.value);
    }
  
    const onEmailChange = (email) => {
        setEmail(email.target.value);
    }
  
    const onMessageChange = (message) => {
        setMessage(message.target.value);
    }

  return(
    <div className="dMapIt-contact">
        <PageTitle title="Contact Support Center"/>
        <div className="contact-lhs">
            <div className="dContact-card-header">
                <div className="dCard-header-inner">
                    <div className="dcontact-us">
                        <p className="dcontact-us-p">Contact our customer support team</p>
                    </div>
                    <div className="dcard-message">
                        <p>You've got questons, we've got answers.</p>
                    </div>
                </div>
            </div>
            <div className="contact-tile-image">
                <img style={{borderRadius: "10px"}} 
                src={callCenter} 
                alt="Contact MapIt support center" 
                width="500" 
                height="293"/>
            </div>
        </div>
        <div id="contact" className="dContact-div">
        <div className="dContact-card-outter">
            <div className="dContact-card-inner">
                <div className="dContact-card-info-outter">
                    <div className="dContact-card-info">
                    <div className="dcontact-card-info-inner">
                        <div className="dName">
                        <label className="contact-label" htmlFor="Text">Name</label>
                        <input
                            className="dName-input"
                            type="name"
                            name="name"
                            id="name"
                            onChange={onNameChange}
                        />
                        </div>
                        <div className="dE-mail">
                        <label className="contact-label" htmlFor="email-address">Email</label>
                        <input
                            className="dE-mail-input"
                            type="email"
                            name="email-address"
                            id="email-address"
                            onChange={onEmailChange}
                        />
                        </div>
                        <div className="dMessage">
                        <label className="contact-label" htmlFor="email-address">Message</label>
                        <textarea
                            className="dMessage-box"
                            type="message"
                            name="message"
                            id="message"
                            cols="40"
                            rows="6"
                            onChange={onMessageChange}
                        />
                        </div>
                    </div>
                    </div>
                    {displayMsg === false
                    ?
                    <div className="dSubmit-button">
                        <MapItButton onSubmit={onSubmit} text="Contact" className={isMobile ? "mMapitButton" : "mapitButton"}></MapItButton>
                    </div>
                    :
                    <div className="dSubmit-button">
                        <p className='dsubmit-text'>We have recieved your message and will contact you within 1 business day.</p>
                    </div>
                    }
              
                </div>
            </div>
            { loading &&
                <div className="dloading">
                    <TailSpin
                        height="4vh"
                        width="4vh"
                        color='#FCC81A'
                        ariaLabel='loading'
                    />
                </div>
            }
        </div>
        </div>
    </div>
  );
}

export default ContactUs;