export default class Snapshot {
    constructor(snapshot_id, image="", coordinatex, coordinatey, notes="", local_file_path="", remote_file_path="",width=0,height=0,fileType="") {
        this.snapshot_id = snapshot_id;
        this.image = image;
        this.coordinatex = coordinatex;
        this.coordinatey = coordinatey;
        this.notes = notes;
        this.local_file_path = local_file_path;
        this.remote_file_path = remote_file_path;
        this.width = width;
        this.height=height;
        this.stickyNotes = {};
        this.fileType = fileType;
    }
}