import React, {useState, useEffect} from 'react';
import "./PromoText.css";

import Download from '../Download/Download';

const PromoText = ({create, setCreate}) => {

  const [firstKeyword, setFirstKeyword] = useState(["Engage","Stream", "Capture", "Upload", "Recall"]);
  const [secondKeyword, setSecondKeyword] = useState(["Buyers", "Videos", "Images", "Plans", "Listings"]);
  const [curIndex, setCurIndex] = useState(0);

  useEffect(() => {
    setTimeout(incrementCurIndex, 2000);
  }, []);

  useEffect(() => {
    setTimeout(incrementCurIndex, 2000);
  }, [curIndex]);

  const incrementCurIndex = () => {
    setCurIndex((curIndex+1)%firstKeyword.length);
  }

  return(
    <div className="dhtext-outter">
      <div className="dhtext-inner">
        <div className="dhtext-div">
            <p className="dhtext-1">
              {firstKeyword[curIndex]} {secondKeyword[curIndex]} 
            </p>
            <p className="dhtext-3">
              all in one place.<br/>
            </p>
        </div>
        <div className="dhtext-div">
            <p className='dhtext-2'>
                {/* The best way for your clients to view real estate listings.
                Create interactive maps using your professioinal photos and floor plans. */}
                Transform your listing into a dynamic, live interactive link. 
                Imagine buyers seamlessly navigating through each room, experiencing the layout firsthand, and visualizing their future in your space—all with just a click. 
                {/* Interactive floor plans are the best way to help buyers relive your listing.
                Share live links on the MLS, generate QR codes for your opens, and engage buyers. */}
                {/* Seamless team and customer communication via document sharing.
                Mitigate legal liabilities, generate reports, and enable the capture of on-site photos and videos. */}
            </p>
        </div>
        <Download create={create} setCreate={setCreate}></Download>
      </div>
    </div>
  );
}

export default PromoText;