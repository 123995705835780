import React, {useState} from 'react';
import "./MGetStarted.css";

import googleIcon from '../../../Assets/GooglePlayIcon.png';
import appleIcon from '../../../Assets/AppleAppStore.png'
import MapItButton from '../../Common/MapItButton/MapItButton';

import { Link } from 'react-router-dom';

const MGetStarted = ({buttonStyle}) => {

  const [clicked, setClicked] = useState(false);

  const onSubmit = () => {
    setClicked(!clicked);
  }

  return(
    <div className="mGetStarted-membership">
        <div id="download" className="mGetStarted-member-inner">
            <div className="mGetStarted-inner-div">
              <Link to="/create" spy={true} smooth={true}>
                <MapItButton onSubmit={onSubmit} text="Get started today" className={buttonStyle}></MapItButton>
              </Link>
            </div>
        </div>
    </div>
  );
}

export default MGetStarted;