import React, {useState} from 'react';
import "./MDownload.css";

import googleIcon from '../../../Assets/GooglePlayIcon.png';
import appleIcon from '../../../Assets/AppleAppStore.png';
import MapItButton from '../../Common/MapItButton/MapItButton';

import { isAndroid } from 'react-device-detect';
import { Link } from 'react-router-dom';


const MDownload = () => {

  const [clicked, setClicked] = useState(false);

  const onSubmit = () => {
    setClicked(!clicked);
  }

  return(
    <div className="MapIt-download">
        <div id="download" className="MapIt-download-inner">
            <div className="download-spacer"></div>
            <Link to="/create" spy={true} smooth={true}>
            {/* <a className="download-link"href={isAndroid ? 'https://play.google.com/store/apps/details?id=rai.mapit':'https://apps.apple.com/us/app/mapit/id1642247604'}> */}
                <MapItButton onSubmit={onSubmit} text="Get started today" className="download"></MapItButton>
            {/* </a> */}
            </Link>
            <div className="download-spacer"></div>
        </div>
    </div>
  );
}

export default MDownload;