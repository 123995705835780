export default class Floorplan {
    constructor(server_floorplan_id, image = "", pos = "", notes = "", local_file_path = "", remote_file_path = "", width = 0, height = 0, pdf=false, ssSize = 0.05) {
        this.server_floorplan_id = server_floorplan_id;
        this.image = image;
        this.pos = pos;
        this.notes = notes;
        this.local_file_path = local_file_path;
        this.remote_file_path = remote_file_path;
        this.snapshots = {};
        this.stickyNotes = {};
        this.width = width;
        this.height = height;
        this.pdf = pdf;
        this.ssSize = ssSize;
    }
}