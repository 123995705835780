import React, { useState } from 'react';
import "./MDownloadApp.css";

import leftArrow from '../../../Assets/left-arrow-white.png';
import rightArrow from '../../../Assets/right-arrow-white.png'

const MDownloadApp = () => {

  const [idx, setIdx] = useState(0);

  const rightClick = () => {
    setIdx((idx+1)%3);
  }

  const leftClick = () => {
    if (idx > 0) {
        setIdx(idx-1);
    }
  }

  return(
    <div className="mmapit-download">
        <div className="mdownload-text">
            <div className="mdownload-div-title">
                <p className="mdownload-1">
                Grow, Market and Organize
                </p>
                <p className="mdownload-3">
                Available on your featured App Store
                </p>
            </div>
            <div className="mdownload-div-text">
                <p className='mdownload-2'>
                Everything you need to organize your clients and team.
                </p>
            </div>
        </div>
        <div className="mdownload-tiles">
            { idx === 0 && 
                <div className="mdownload-tile1">
                    <div className="mdownload-tile-image">
                    <iframe 
                        className='mdownload-feature1'
                        src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                        frameBorder="0"
                        allow="accelerometer"
                        allowFullScreen
                    />
                    </div>
                    <div className="mdownload-tile-text">
                        <div className="mdownload-tile-title">
                            <p className="mdownload-tile-1">
                            Remote Work Validation
                            </p>
                        </div>
                        <div className="mdownload-tile-desc">
                            <p className='mdownload-tile-2'>
                            Monitor your sites with video and image updates.<br></br>
                            Track daily reports and mitigate on-site risk.
                            </p>
                        </div>
                    </div>
                </div>
            }
            {idx === 1 && 
            <div className="mdownload-tile2">
                <div className="mdownload-tile-image">
                    <iframe 
                        className='mdownload-feature1'
                        src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                        frameBorder="0"
                        allow="accelerometer"
                        allowFullScreen
                    />
                </div>
                <div className="mdownload-tile-text">
                    <div className="mdownload-tile-title">
                        <p className="mdownload-tile-1">
                        Market Your Projects
                        </p>
                    </div>
                    <div className="mdownload-tile-desc">
                        <p className='mdownload-tile-2'>
                       Share your projects on social media using weblinks.<br></br>Or generate a QR code for on-site marketing.
                        </p>
                    </div>
                </div>
            </div>
            }
            {idx === 2 &&
            <div className="mdownload-tile3">
                <div className="mdownload-tile-image">
                    <iframe 
                        className='mdownload-feature1'
                        src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                        frameBorder="0"
                        allow="accelerometer"
                        allowFullScreen
                    />
                </div>
                <div className="mdownload-tile-text">
                    <div className="mdownload-tile-title">
                        <p className="mdownload-tile-1">
                        Improve Customer Service
                        </p>
                    </div>
                    <div className="mdownload-tile-desc">
                        <p className='mdownload-tile-2'>
                        Notify clients with updates and reminders on projects.<br></br>Close deals faster by communicating uptodate information.
                        </p>
                    </div>
                </div>
            </div>
            }
        </div>
        <div className="arrowDiv">
            <div className="larrowDiv">
                <img src={leftArrow} width="32" height="22" onClick={() => leftClick()}/>
            </div>
            <div className="dotDiv">
                <div className="dotDivInner">
                    <ul><li className={idx===0?'dot':'dot-opc'}></li></ul>
                </div>
                <div className="dotDivInner">
                    <ul><li className={idx===1?'dot':'dot-opc'}></li></ul>
                </div>
                <div className="dotDivInner">
                    <ul><li className={idx===2?'dot':'dot-opc'}></li></ul>
                </div>                
            </div>
            <div className="rarrowDiv">
                <img src={rightArrow} width="32" height="22" onClick={() => rightClick()}/>
            </div>
        </div>
    </div>
  );
}

export default MDownloadApp;