import React, {useState} from 'react';
import "./Download.css";

import { Link } from 'react-router-dom';

import googleIcon from '../../../Assets/GooglePlayIcon.png';
import appleIcon from '../../../Assets/AppleAppStore.png'
import MapItButton from '../../Common/MapItButton/MapItButton';

const Download = ({create, setCreate}) => {

  const onSubmit = () => {
    setCreate(true);
  }

  return(
    <div className="dMapIt-membership">
        <div id="download" className="dMapIt-member-inner">
            <div className="dl-inner-div">
            <Link to="/create" spy={true} smooth={true}>
              <MapItButton onSubmit={onSubmit} text="Get started for $9.99" className="ddownload"></MapItButton>
            </Link>
            </div>
        </div>
    </div>
  );
}

export default Download;