import React, {useState, useEffect} from 'react';
import "./MPromoText.css";

import MDownload from '../MDownload/MDownload';

const MPromoText = () => {

  const [firstKeyword, setFirstKeyword] = useState(["Engage","Stream", "Capture", "Upload", "Recall"]);
  const [secondKeyword, setSecondKeyword] = useState(["Buyers", "Videos", "Images", "Plans", "Listings"]);
  const [curIndex, setCurIndex] = useState(0);

  useEffect(() => {
    setTimeout(incrementCurIndex, 2000);
  }, []);

  useEffect(() => {
    setTimeout(incrementCurIndex, 2000);
  }, [curIndex]);

  const incrementCurIndex = () => {
    setCurIndex((curIndex+1)%firstKeyword.length);
  }

  return(
    <div className="mdhtext-outter">
      <div className="mdhtext-inner">
        <div className="mdhtext-div">
            <p className="mdhtext-1">
              {firstKeyword[curIndex]} {secondKeyword[curIndex]} 
            </p>
            <p className="mdhtext-3">
              all in one place.<br/>
            </p>
        </div>
        <div className="mdhtext-div">
            <p className='mdhtext-2'>
              Transform your listing into a dynamic, live interactive link. 
              Imagine buyers seamlessly navigating through each room, experiencing the layout firsthand, and visualizing their future in your space—all with just a click. 
            </p>
        </div>
        <MDownload></MDownload>
      </div>
    </div>
  );
}

export default MPromoText;