import React, {useState, useEffect} from 'react';
import "./MCreate.css";

import MStartNow from '../MStartNow/MStartNow';
import under_dev from '../../../Assets/under_development.png'

const MCreate = () => {

  const industryList = ["RE", "C", "ID","T","BM"];

  const fullIndstNames = ["Real Estate", "Construction", "Interior Design", "Trades", "Building Mangement"]

  const [curIndst, setCurIndst] = useState(0);
  const [clickIdx, setClickIdx] = useState(0);

  useEffect(() => {
    setTimeout(incrementCurIndex, 6000);
    window.scrollTo(0, 0);
  }, [curIndst]);

  const incrementCurIndex = () => {
    setClickIdx((clickIdx+1)%industryList.length);
    setCurIndst((clickIdx+1)%industryList.length);
  }

  return(
    <div className="mcreate-outter">
        <div className="mcreate-div">
          <img 
          style={{height: "54.9vw", width: "90vw"}} 
          src={under_dev}
          alt="MapIt mobile create feature"/>
        </div>
        <div className="mindustry-list-outter">
          <p className='mcreate-3'>
              MapIt mobile coming soon.
          </p>
          <p className='mcreate-3'>
              Please visit our desktop website.
          </p>
        </div>
    </div>
  );
}

export default MCreate;