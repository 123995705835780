import React, {useState} from 'react';
import "./MapItButton.css";
import { Link } from 'react-router-dom';

import {isMobile} from 'react-device-detect';

const MapItButton = ({btnRef, onSubmit, text, className}) => {

  return(
      <div className="mapitButtonDiv">
          <button
          ref={btnRef}
          onClick={onSubmit}
          className={className}>
          {text}
          </button>
      </div>
  );
}

export default MapItButton;

