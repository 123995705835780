import React, {useState} from 'react';
import "./MHome.css";

import MPromoText from "../MPromoText/MPromoText";
import MFeatures from "../MFeatures/MFeatures";
import MFeatureGrid from "../MFeatureGrid/MFeatureGrid";
import MStartNow from '../MStartNow/MStartNow';

import promoMedia from '../../../Assets/promo_gif_mobile.gif'


const MHome = () => {
  const getWidth = () => {
    return window.innerWidth*0.375;
}

const getHeight = () => {
    return window.innerWidth*0.288
}


  return(
    <div className="mhome-root">
    <div className="mhome-outter">
        <MPromoText></MPromoText>
        <div className="mhome-inner">
          <div className="mfpImgDiv">
            {/* <iframe 
              className='mFloorplanImg'
              src={promoMedia}
              frameBorder="0"
              allow="accelerometer"
              allowFullScreen
            /> */}
            <img className='mFloorplanImg' 
            src={promoMedia} 
            width={getWidth()} 
            height={getHeight()}
            alt="Interactive home tour"/>
          </div>
        </div>
    </div>
    <div>
      <MFeatures></MFeatures>
    </div>
    <div>
      <MFeatureGrid></MFeatureGrid>
    </div>
    <div>
      <MStartNow divStyle="mStartNow-outter-home" buttonStyle="mgetStartedButton"></MStartNow>
    </div>
    </div>
  );
}

export default MHome;