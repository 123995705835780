import React from 'react';
import "./Footer.css";

import fb from '../../../Assets/Facebook-128.png';
import insta from '../../../Assets/Insta-128.png';
import ln from '../../../Assets/LinkedIn-128.png';
import twitter from '../../../Assets/Twitter-128.png';

import googleIcon from '../../../Assets/googleplaylogoblack.png';
import appleIcon from '../../../Assets/appleappstoreblack.png';

const Footer = () => {

  return(
    <div className={"Footer"}>
      <div className="Footer-inner">
        <div className='foot-spacer'></div>
        <div className="Social-contacts">
          {/* <a href="https://twitter.com/mapitcc"><img src={twitter} className="Social-icon"/></a>
          <a href="https://www.instagram.com/mapit.cc/"><img src={insta} className="Social-icon"/></a> */}
          {/* <a href="https://www.linkedin.com/company/mapitcc/"><img src={ln} className="Social-icon"/></a>
          <a href="https://www.facebook.com/MapIt-110750868449197"><img src={fb} className="Social-icon"/></a> */}
        </div>
        <div className="Footer-message">
            <p className="copyright-text">2024 MapIt Interactive. All rights reserved.</p>
        </div>
        <div className="Footer-logo">
          {/* <div className="footer-img-div">
                <a href='https://play.google.com/store/apps/details?id=rai.mapit'>
                    <img src={googleIcon} className="footer-icon"/>
                </a>
          </div>          
          <div className="footer-img-div">
              <a href='https://apps.apple.com/us/app/mapit/id1642247604'>
                  <img src={appleIcon} className="footer-icon"/>
              </a>
          </div> */}
        </div>
        <div className='foot-spacer'></div>
      </div>
    </div>
  );
}

export default Footer;