import React, {useState} from 'react';
import "./MContactUs.css";

import {TailSpin} from 'react-loader-spinner';
import MapItButton from '../../Common/MapItButton/MapItButton';
import {isMobile} from 'react-device-detect';

import * as Security from '../../Security/Security';

import callCenter from '../../../Assets/call_center_2.png'

const prod_host = "https://infinite-mountain-18925.herokuapp.com";

const MContactUs = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [displayMsg, setDisplayMsg] = useState(false);

    const onSubmit = () => {
        setClicked(!clicked);
        if(Security.valEmailInput(email)&&Security.valNameInput(name)&&Security.valNotesInput(message)) {
          console.log("send network request to submit password data and access code")
          setLoading(true);
          fetch(`${prod_host}/contactus`, {
          method: 'post',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            name: name,
            email: email,
            message: message
          })
          })
          .then(response => response.json())
          .then(msg => {
            setLoading(false);
            setDisplayMsg(true);
            if(msg === "password updated") {
                // let user know we have recieved your message
            } else {
              console.log(msg);
              // let user know auth failed - todo
            }
          })
          .catch(console.log);
        } else {
          console.log("Debug Log: user input did not pass security validation test");
          // let user know something is wrong wiht input - todo
        }
    }

    const onNameChange = (name) => {
        setName(name.target.value);
    }
  
    const onEmailChange = (email) => {
        setEmail(email.target.value);
    }
  
    const onMessageChange = (message) => {
        setMessage(message.target.value);
    }

  return(
    <div className="mdMapIt-contact">
        <div className="mcontact-lhs">
            <div className="mdContact-card-header">
                <div className="mdCard-header-inner">
                    <div className="mdcontact-us">
                        <p className="mdcontact-us-p">Contact our customer support team</p>
                    </div>
                    <div className="mdcard-message">
                        <p>You've got questons, we've got answers.</p>
                    </div>
                </div>
            </div>
            <div className="mcontact-tile-image">
                <img className="mcontact-iframe" 
                style={{borderRadius: "10px"}}
                src={callCenter} 
                width="500" 
                height="293"
                alt="MapIt support center"/>
            </div>
        </div>
        <div id="contact" className="mdContact-div">
        <div className="mdContact-card-outter">
            <div className="mdContact-card-inner">
                <div className="mdContact-card-info-outter">
                    <div className="mdContact-card-info">
                    <div className="mdcontact-card-info-inner">
                        <div className="mdName">
                        <label className="mcontact-label" htmlFor="Text">Name</label>
                        <input
                            className="mdName-input"
                            type="name"
                            name="name"
                            id="name"
                            onChange={onNameChange}
                        />
                        </div>
                        <div className="mdE-mail">
                        <label className="mcontact-label" htmlFor="email-address">Email</label>
                        <input
                            className="mdE-mail-input"
                            type="email"
                            name="email-address"
                            id="email-address"
                            onChange={onEmailChange}
                        />
                        </div>
                        <div className="mdMessage">
                        <label className="mcontact-label" htmlFor="email-address">Message</label>
                        <textarea
                            className="mdMessage-box"
                            type="message"
                            name="message"
                            id="message"
                            // cols="40"
                            // rows="6"
                            onChange={onMessageChange}
                        />
                        </div>
                    </div>
                    </div>
                    {displayMsg === false
                    ?
                    <div className="mdSubmit-button">
                        <MapItButton onSubmit={onSubmit} text="Contact" className={"mMapitButton"}></MapItButton>
                    </div>
                    :
                    <div className="mdSubmit-button">
                        <p className='mdsubmit-text'>We have recieved your message and will contact you within 1 business day.</p>
                    </div>
                    }
                </div>
            </div>
            { loading &&
                <div className="mdloading">
                    <TailSpin
                        height="4vh"
                        width="4vh"
                        color='#FCC81A'
                        ariaLabel='loading'
                    />
                </div>
            }
        </div>
        </div>
    </div>
  );
}

export default MContactUs;