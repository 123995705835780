import React, {useState} from 'react';

import "./MHeader.css";
import { Link } from 'react-router-dom';

import MapItButton from '../../Common/MapItButton/MapItButton';

import menu from '../../../Assets/menu64.png'
import cross from '../../../Assets/cross-mark.png'

const MHeader = () => {

  const [showMenu, setShowMenu] = useState(false);
  const [clicked, setClicked] = useState(false);

  const menuClick = () => {
    setShowMenu(!showMenu);
  }

  return(
    <div className="mBanner">
        <div className='mmenu-div'>
            <div className="mMapIt-logo-div">
            <Link to="/" spy={true} smooth={true}>
                <img src="https://d13dvi4jdbb8bg.cloudfront.net/MapIt-Logo-768x292px.png" 
                width="157.81vh" 
                height="60vh"
                alt="MapIt logo"/>
            </Link>            
            </div>
            <div className="mmenu-icon">
                <img 
                src={showMenu?cross:menu} 
                width="24" 
                height="24" 
                onClick={() => menuClick()}
                alt="MapIt menu icon"/>
            </div>
        </div>
        { showMenu && 
            <div className="mmenu-tree">
                <Link to="/" spy={true} smooth={true}>
                <div className="mmenu-button" onClick={() => menuClick()}> 
                    <p className="mdemo-text">
                    Home
                    </p>
                </div>
                </Link>
                <Link to="/contact" spy={true} smooth={true}>
                <div className="mmenu-button" onClick={() => menuClick()}> 
                    <p className="mdemo-text">
                    Contact
                    </p>
                </div>
                </Link>
                <div className="mmenu-button-center" onClick={() => menuClick()}> 
                    <p className="mdemo-text">
                    <Link to="/create" spy={true} smooth={true}>
                        <MapItButton onSubmit={()=>{}} text="Create" className="mcreateBtn"></MapItButton>
                    </Link>                             
                    </p>
                </div>
            </div>
        }
    </div>
  );
}

export default MHeader;
