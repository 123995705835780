import React from 'react';
import "./MFooter.css";

const MFooter = () => {

  return(
    <div className={"mFooter"}>
      <div className="mFooter-inner">
        <div className="mFooter-message">
            <p className="mcopyright-text">2024 MapIt Interactive. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default MFooter;