import React from 'react';
import "./StartNow.css";

import googleIcon from '../../../Assets/GooglePlayIcon.png';
import appleIcon from '../../../Assets/AppleAppStore.png'
import GetStarted from '../GetStarted/GetStarted'

const StartNow = ({divStyle, buttonStyle, create, setCreate}) => {

  return(
    <div className={divStyle}>
      <div className="StartNow-inner">
        <div className="StartNow-div-title">
            <p className="StartNow-1">
              Business Communication
            </p>
            <p className="StartNow-3">
              with smart floor plans.<br/>
            </p>
        </div>
        <div className="StartNow-div-text">
            <p className='StartNow-2'>
                Couple plans and on-site pictures with ease.
            </p>
        </div>
        <GetStarted buttonStyle={buttonStyle} create={create} setCreate={setCreate}></GetStarted>
      </div>
    </div>
  );
}

export default StartNow;