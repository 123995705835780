import React from 'react';
import "./Header.css";

import MapItButton from '../../Common/MapItButton/MapItButton';

import { Link } from 'react-router-dom';

const Header = ({create, setCreate}) => {

  const onDelSS = () => {
    console.log("snapshot del pressed");
    // setSSDelActi(false);
  }

  const onCanDelSS = () => {
    console.log("snapshot cancel pressed");
    // setSSDelActi(false);
  }

  return(
  <div className={create?"createBanner":"Banner"}>
    <div className="dMapIt-logo-div">
      <Link to="/" spy={true} smooth={true}>
        <img 
        src="https://d13dvi4jdbb8bg.cloudfront.net/MapIt-Logo-768x292px.png" 
        width="157.81vh" 
        height="60vh"
        alt="MapIt logo"/>
      </Link>
    </div>
    <div className="Spacer1"></div>
    <div className="Menu">
      <div className='menu-item-div'>
        <p onClick={() => setCreate(false)} className="Menu-item">
          <Link className="Link-style" to="/" spy={true} smooth={true}>Home</Link>
        </p>
      </div>
      {/* <div>
        <p onClick={() => setCreate(false)} className="Menu-item">
        <Link className="Link-style" to="/industries" spy={true} smooth={true}>Industries</Link>
        </p>
      </div>
      <div>
        <p onClick={() => setCreate(false)} className="Menu-item">
        <Link className="Link-style" to="/download" spy={true} smooth={true}>Download</Link>
        </p>
      </div> */}
      <div className='menu-item-div'>
        <p onClick={() => setCreate(false)} className="Menu-item">
        <Link className="Link-style" to="/contact" spy={true} smooth={true}>Contact</Link>
        </p>
      </div>
      <div className="create-btn-div">
        <p onClick={() => setCreate(true)} className="Menu-item">
        <Link className="Link-style-create" to="/create" spy={true} smooth={true}>
          <MapItButton onSubmit={()=>{}} text="Create" className="createBtn"></MapItButton>
        </Link>
        </p>
      </div>
      {/* <div>
        <p className="Menu-item">
          Login
        </p>
      </div> */}
    </div>
    <div className="Spacer2"></div>
  </div>
  );
}

export default Header;

