import React, {useState} from 'react';
import "./MFeatures.css";

const MFeatures = () => {

    const [firstKeyword, setFirstKeyword] = useState(["Organize", "Generate", "Share", "Stream", "Engage", "Capture", "Take"]);
    const [secondKeyword, setSecondKeyword] = useState(["Documents", "Reports", "Work", "Videos", "Customers", "Images", "Notes"]);
    const [curIndex, setCurIndex] = useState(0);

    const getWidth = () => {
        return window.innerWidth*0.95;
    }

    const getHeight = () => {
        return window.innerWidth*0.5343
    }

  return(
    <div className="mfeatures-outter">
        <div className="mfeature-listing">
            <div className="mfeature-text">
                <p className='mbrochure-title'>
                Create Digital Brochures
                </p>
                <p className='mbrochure-text'>
                    Take your videos, pictures and notes in one place.
                </p>
                <p className='mbrochure-text'>
                    Build smart floor plans and market them with a global audience;
                </p>
                <p className='mbrochure-text'>
                    igniting lead generation.
                </p>
            </div>
            <div className="mfeature-media">
                <video height={getHeight()} width={{getWidth}} className="promoVideo" controls>
                    <source src="https://d13dvi4jdbb8bg.cloudfront.net/mapit-promo-video-2.mp4" type="video/mp4"></source>
                </video>            
            </div>
        </div>
        {/* <div className="feature-cloud">
            <div className="feature-text">
                <p className='cloud-title'>
                Unlimited Cloud Storage for your documents, videos and photos.
                </p>
            </div>
            <div className="feature-media">
                <iframe 
                className='scheduling-ad'
                src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                frameBorder="0"
                allow="accelerometer"
                allowFullScreen
                />            
            </div>
        </div>
        <div className="feature-listing">
            <div className="feature-media">
            <iframe 
                className='scheduling-ad'
                src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                frameBorder="0"
                allow="accelerometer"
                allowFullScreen
                />              </div>
            <div className="feature-text">
                <p className='feature-p'>
                Market your listings by creating digital brocures and sharing them with a global audience
                Generate QR codes to share the listing with potential buyers
                </p>
            </div>
        </div>
        <div className="feature-listing">
            <div className="feature-text">
                <p className='feature-p'>
                Unlimited Cloud Storage for your documents, videos and photos.
                </p>
            </div>
            <div className="feature-media2">
            <iframe 
                className='scheduling-ad'
                src="https://mapit-asset-bucket.s3.us-west-2.amazonaws.com/MapIt_Demo_Video.mp4"
                frameBorder="0"
                allow="accelerometer"
                allowFullScreen
                />              </div>
        </div> */}
    </div>
  );
}

export default MFeatures;