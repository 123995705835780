import React, { useEffect, useState } from 'react';
import "./Home.css";

import PromoText from "../PromoText/PromoText";
// import PromoBestText from "../PromoText/PromoBestText";
import Features from "../Features/Features";
import FeatureGrid from "../FeatureGrid/FeatureGrid";
import StartNow from '../StartNow/StartNow';

import promoMedia from '../../../Assets/promo_gif_2.gif';

import PageTitle from '../../Common/PageTitle/PageTitle'; // Adjust the path as per your file structure

const Home = ({create, setCreate}) => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect (() => {
    function updateWidth () {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    setWindowWidth(window.innerWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);


  const getWidth = () => {
      return windowWidth*0.375;
  }

  const getHeight = () => {
      return windowWidth*0.288
  }

  return(
    <div className="home-root">
      <PageTitle title="Create Interactive Floor Plans" />
      <div className="home-outter">
          <PromoText create={create} setCreate={setCreate}></PromoText>
          <div className="home-inner">
            <div className="fpImgDiv">
              <img className='promoVideo' 
              src={promoMedia} 
              width={getWidth()} 
              height={getHeight()}
              alt="Interactive home tour"/>
            </div>
          </div>
      </div>
      <div>
        <Features></Features>
      </div>
      <div>
        <FeatureGrid></FeatureGrid>
      </div>
      <div>
        <StartNow create={create} setCreate={setCreate} divStyle="StartNow-outter-home" buttonStyle="getStartedButton"></StartNow>
      </div>
    </div>
  );
}

export default Home;