import React, {useState} from 'react';
import "./GetStarted.css";

import { Link } from 'react-router-dom';

import googleIcon from '../../../Assets/GooglePlayIcon.png';
import appleIcon from '../../../Assets/AppleAppStore.png'
import MapItButton from '../../Common/MapItButton/MapItButton';

const GetStarted = ({buttonStyle, setCreate}) => {

  const onSubmit = () => {
    setCreate(true);
  }

  return(
    <div className="GetStarted-membership">
        <div id="download" className="GetStarted-member-inner">
            <div className="GetStarted-inner-div">
            <Link to="/create" spy={true} smooth={true}>
              <MapItButton onSubmit={onSubmit} text="Get started for $9.99" className={buttonStyle}></MapItButton>
            </Link>
            </div>
        </div>
    </div>
  );
}

export default GetStarted;