export const charNotAllowList = ["'",";",':'];
export const emailValRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailValRegexBack = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const checkNotAllowList = (input) => {
    for (let char of charNotAllowList) {
        if (input.includes(char)) {
            return false;
        }
    }
    return true;
}

export const limitLength = (input, max) => {
    if (input.length < max) {
        return true;
    } else {
        return false;
    }
}

export const valEmailInput = (email) => {
    if(limitLength(email, 240) && email && checkNotAllowList(email)) {
        let result = emailValRegex.test(email);
        if (result) {
            // validate email doesn't bounce - use 3rd party api - potential security threat - todo
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const valNameInput = (userName) => {
    if (limitLength(userName, 240) && checkNotAllowList(userName)) {
        return true;
    } else {
        return false;
    }
}

export const valNotesInput = (notes) => {
    if (limitLength(notes, 1000)) {
        return true;
    } else {
        return false;
    }
}