import React, {useState, useEffect} from 'react';
import "./Preview.css";

import MapItButton from '../../Common/MapItButton/MapItButton';
import { Link } from 'react-router-dom';
import {isMobile, isMobileOnly} from 'react-device-detect'
import Footer from '../../Common/Footer/Footer';


const Preview = ({floorplan, fpWidth, fpHeight, ssObjs, setPreview, snapSize, setSharePressed}) => {
  const [selectedSS, setSelectedSS] = useState(0);
  const [ssViewActive, setSSViewActive] = useState(false);
  const [winX, setWinX] = useState(window.innerWidth);
  const [winY, setWinY] = useState(window.innerHeight);
  const [fpX, setFpx] = useState(((winX - (fpWidth*winX))/2)/winX);
  const [fpY, setFpy] = useState(((winY - (fpHeight*winY))/2)/winY);

  const horizontalTab = (isMobile && window.innerWidth>window.innerHeight)?true:false;

  const deviceFpRatio = (isMobile&&!horizontalTab) ? 1:0.8;
  const deviceSsRatio = (isMobile&&!horizontalTab) ? 0.9:0.4;
  const deviceSnapRatio = (isMobileOnly) ? 1.5:1;

  useEffect(() => {
    const handleResize = () => {
        setWinX(window.innerWidth);
        setWinY(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const imageClick = (ssId) => {
    setSelectedSS(ssId);
  }

  const onBackFp = () => {
    setSSViewActive(false);
    setSelectedSS(0);
    setSharePressed(true);
    setPreview(false);
  }

  const leftOrRight = (ssId, width) => {
    if (((fpX*winX) + (ssObjs[ssId].coordinatex*fpWidth*winX) + ssObjs[ssId].renderWidth*winX*0.4)
    > (fpX*winX+(fpWidth*winX))) {
      return -1*ssObjs[ssId].renderWidth*winX*0.4+(fpWidth*winX*snapSize); // magic number 0.4? - hard type this
    } else {
      return 0;
    }
  }

  const topOrBottom = (ssId, height) => {
    if (((fpY*winY) + (ssObjs[ssId].coordinatey*fpHeight*winY) + ssObjs[ssId].renderHeight*winY*0.4)
    > (fpY*winY+(fpHeight*winY))) {
      return -1*ssObjs[ssId].renderHeight*winY*0.4+(fpWidth*winX*snapSize);
    } else {
      return 0;
    }
  }

  const getSnapshotStyle = (ssId) => {
    if (ssObjs[ssId].coordinatex !== 0 || ssObjs[ssId].coordinatey !== 0) {
      return {position: 'absolute', 
      left: ssId!==selectedSS
      ? (fpX*winX) + (ssObjs[ssId].coordinatex*fpWidth*winX)
      : ((fpX*winX) + (ssObjs[ssId].coordinatex*fpWidth*winX) + leftOrRight(ssId)), 
      top: ssId!==selectedSS
      ? ((fpY*winY) + (ssObjs[ssId].coordinatey*fpHeight*winY))
      : ((fpY*winY) + (ssObjs[ssId].coordinatey*fpHeight*winY) + topOrBottom(ssId))
      };
    } else {
      return {};
    }
  }

  const getSSWidth = (ssId) => {
    let ssWidth = 0;
    if (ssObjs[ssId].coordinatex != 0 || ssObjs[ssId].coordinatey != 0) {
      if (ssId === selectedSS) {
        ssWidth = ssObjs[ssId].renderWidth*winX*0.4;
      } else {
        ssWidth = fpWidth*winX*snapSize;
      }
    }
    return ssWidth;
  }

  const getSSHeight = (ssId) => {
    let ssHeight = 0;
    if (ssObjs[ssId].coordinatex != 0 || ssObjs[ssId].coordinatey != 0) {
      if (ssId === selectedSS) {
        ssHeight = ssObjs[ssId].renderHeight*winY*0.4;
      } else {
        ssHeight = fpWidth*winX*snapSize; // using width here is correct to make square icons
      }
    }
    return ssHeight;
  }
  
  const onLargeImageClick = () => {
    setSelectedSS(0);
  }

  function Logo () {
    return (
      <div className={"preview_logo"}>
        <div className={"dMapIt-logo-div"}>
        <img src="https://d13dvi4jdbb8bg.cloudfront.net/MapIt-Logo-768x292px.png" width="157.81vh" height="60vh"/>
        </div>
      </div>
    )
  }

  return (
    <div className="preview">
      <Logo/>
      { floorplan && 
      <div className="edit-outter">
        <div className="snapshots">
          { !ssViewActive &&
            <>
            {Object.keys(ssObjs).map((ssId, index) => (
              <div className="ss-img">
                {ssId !== selectedSS
                ?
                <img 
                key={index} 
                src={ssObjs[ssId].local_file_path} 
                style={{
                  ...{border: ssId!==selectedSS?"1.5px solid white":"3px solid #FCC81A", 
                  borderRadius: "5px", 
                  cursor: "pointer", 
                  width: getSSWidth(ssId), 
                  height: getSSHeight(ssId)}, 
                  ...getSnapshotStyle(ssId)}} 
                onClick={() => imageClick(ssId)} 
                alt={`interior home pictures ${ssId}`} />
                :
                <img
                src={ssObjs[selectedSS].local_file_path} 
                style={{
                  ...{border: "1.5px solid white", 
                  zIndex:1, borderRadius: "5px", 
                  cursor: "pointer", 
                  width: getSSWidth(ssId), 
                  height: getSSHeight(ssId)}, 
                  ...getSnapshotStyle(ssId)}} 
                onClick={() => onLargeImageClick()}
                alt={`interior home pictures ${selectedSS}`} /> 
                }
              </div>          
            ))}
            </>
          }
        </div>
        <div className="preview_floorplan">
          <img src={floorplan} width={fpWidth*winX} height={fpHeight*winY}/>
        </div>
        <div className="preview-edit-btns">
            <div className="preview-view-btn">
                <Link className="Link-style" to="/create" spy={true} smooth={true}>
                    <MapItButton onSubmit={onBackFp} text="Back" className="viewbtn"/>
                </Link>
            </div>
        </div>
      </div>
      }
      <Footer create={true}></Footer>
    </div>
  );
}

export default Preview;